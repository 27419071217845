import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import CheckInService from '../../../api/CheckInService.js'
import Page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'

export default{
	name:'BookList',
	data(){
		return{
			pageIndex:1,
			pageSize:10,
			pageCount:0,
			mainList:[],//列表
			searchWord:'',//搜索
			curClassType:0,//当前分类
			HotElementList:[],//热门词条
			HotEntryList:[],//热门条目
		}
	},
	methods:{
		GetResListNoClass(){ //获取列表
			var that = this
			CheckInService.GetResListNoClass(that.pageIndex,that.pageSize,that.searchWord.trim(),that.$route.query.type).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.mainList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		pageChange(page){
			this.pageIndex = page
			this.GetResListNoClass()
			window.scrollTo(0,0)
		},
		changeClass(type){ //切换分类
			this.curClassType = type
			this.pageIndex = 1
			this.GetResListNoClass()
		},
		GetHotElementList(){ //获取热门词条
			var that = this
			CheckInService.GetHotResList(0,5,'').then(res=>{
				if(res.Tag == 1){
					that.HotElementList = res.Result
				}
			})
		},
		GetHotEntryList(){
			var that = this
			CheckInService.GetHotResList(1,5,'').then(res=>{
				if(res.Tag == 1){
					that.HotEntryList = res.Result
				}
			})
		},
		toSearch(){ //搜索
			this.pageIndex = 1
			this.GetResListNoClass()
			if(this.searchWord.trim()){
				CheckInService.SearchRecord(this.searchWord.trim(),this.$UserID).then(res=>{
					console.log(res)
				})
			}
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl+'EntryDetail?id='+id)
		},
		toElementDetail(id){ //词条详情
			window.open(this.$WebUrl+'ElementDetail?id='+id)
		},
		toBookDetail(id){ //图书详情
			window.open(this.$WebUrl+'BookDetail?id='+id)
		},
	},
	created(){
		this.searchWord = this.$route.query.keyWord?this.$route.query.keyWord:''
		if(this.$route.query.keyWord){
			CheckInService.SearchRecord(this.searchWord.trim(),this.$UserID).then(res=>{
				console.log(res)
			})
		}
		this.GetResListNoClass()
		this.GetHotElementList()
		this.GetHotEntryList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'Page':Page,
		'NoData':NoData
	}
}